import essentialsApi from 'api/essentials';
import API from '../../settings/constants'

const essentialsModule = {
  state: {
    apps: [],
    frequentlyUsed:[]
  },
  getters: {
    myEssentialsApps: state => state.apps,
    frequentlyUsed: state=> state.frequentlyUsed
  },
  mutations: {
    setMyEssentialsApps(state, apps) {
      state.apps = apps;
    },
    setFrequentlyUsed(state,regularUsed){
      state.frequentlyUsed = regularUsed;
    }
  },
  actions: {
    async fetchMyEssentialsApps({commit,dispatch},payload) {
      if(!payload){
        payload = { retry : undefined};
      }
      const wasLoading = this.getters.isLoading;
      let tokens = this.getters.tokens;
      if (!wasLoading) {
        commit('setIsLoading', true);
      }
      let errorObj = {
        errorStatus : false,
        errorMessage : ''
      }
      commit('setAppError',errorObj); 
      return await essentialsApi.fetchApps(tokens, API.MIGRATED)
        .then((response) => {
          let debug = "Fetch Apps:"+JSON.stringify(response);
          //eslint-disable-next-line
          NativeJS.debugLog(debug);
          let myApps = [];
          if(response.data){
           myApps = API.MIGRATED ? response.data : response.data.essentials ? response.data.essentials : [];
          }else if(response.errors){
            let status = "";
            if(response.errors && response.errors.length > 0){
              status = response.errors[0].status+":";
            } 
            let errorObj = {
              errorStatus : true,
              errorMessage : status +' Something went wrong, Please try again later.'
            }
            commit('setAppError',errorObj);
          }
          return commit('setMyEssentialsApps', myApps);
        })
        .catch((error)=>{
          let debug = "Fetch Directory:"+JSON.stringify(error);
          //eslint-disable-next-line
          NativeJS.debugLog(debug);
          let status = "";
          if(error && error.errors && error.errors.length > 0){
            status = error.errors[0].status+":"; }else{ status = error; }
          if(status.indexOf('403') != -1 || status.indexOf('401') != -1){
            let errorStatus = status.indexOf('403') != -1 ? '403' : '401';
            //eslint-disable-next-line
            NativeJS.refreshTokens(errorStatus, '');
          }else if(status.toLowerCase().indexOf('network') != -1 && (payload.retry == undefined)){
            dispatch('replay',{reqName:'fetchMyEssentialsApps',payload:null,retry:true})
          }else{
          let errorObj = {
            errorStatus : true,
            errorMessage : status +' Something went wrong, Please try again later.'
          }
          commit('setAppError',errorObj);
        }
          let myApps = [];
          return commit('setMyEssentialsApps', myApps);
        })
        .finally(() => {
          commit('setIsLoading', false);
        });
    },
    async frequentlyUsed({commit}) {
      const wasLoading = this.getters.isLoading;
      if (!wasLoading) {
        commit('setIsLoading', true);
      }
      return await essentialsApi.fetchApps(123)
        .then((response) => {
          let frequentlyUsed = response.frequentlyUsed;
          return commit('setFrequentlyUsed', frequentlyUsed);
        })
        .finally(() => {
          if (!wasLoading) {
            commit('setIsLoading', false);
          }
        });
    },
    setLoader({commit},status){
      commit("setIsLoading",status);
    },
    async essentialsCookie({commit},url){
      const wasLoading = this.getters.isLoading;
      let tokens = this.getters.tokens;
      if (!wasLoading) {
        commit('setIsLoading', true);
      }
      let errorObj = {
        errorStatus : false,
        errorMessage : ''
      }
      commit('setAppError',errorObj);
      let cookieValue = "";
      let timestamp = new Date().getTime();
      let debug = "refresh-token from native app :"+ JSON.stringify(tokens.refresh_token);
      //eslint-disable-next-line
      NativeJS.debugLog(debug);
      let isCookiePresent = localStorage.getItem('essentialCookie_'+ tokens.user_name);
      if(isCookiePresent){
         let parseCookie = JSON.parse(isCookiePresent);
         let validCookie = (parseCookie.timestamp + 7200000) > timestamp;
         if(validCookie){
           cookieValue = parseCookie.cookie;
         }
      }
      if(cookieValue == ""){
        var response = essentialsApi.essentialCookie(tokens)
        let debug = "Set Cookie:"+JSON.stringify(response);
        //eslint-disable-next-line
        NativeJS.debugLog(debug);
        if(response && response.data.id_token){
        cookieValue = response.data.id_token;
        localStorage.setItem('essentialCookie_'+tokens.user_name, JSON.stringify({ cookie: cookieValue,timestamp: timestamp }))
        }
        commit('setIsLoading', false);
          let appUrl = url && url.appUrl ? url.appUrl : '';
          let appFrom = url && url.appFrom ? url.appFrom : '';
          let appName = url && url.appName ? url.appName : 'app';
          //let cookieString = "iPlanetDirectoryPro="+cookieValue+';domain=.det.nsw.edu.au;';
          //document.cookie = cookieString;
          // eslint-disable-next-line
          if(NativeJS != 'undefined'){
            // eslint-disable-next-line
            NativeJS.openEssential(appName,appUrl,cookieValue,appFrom);
          }
      } else{
        let appUrl = url && url.appUrl ? url.appUrl : '';
        let appFrom = url && url.appFrom ? url.appFrom : '';
        let appName = url && url.appName ? url.appName : 'app';
        //let cookieString = "iPlanetDirectoryPro="+cookieValue+';domain=.det.nsw.edu.au;';
        //document.cookie = cookieString;
        // eslint-disable-next-line
        if (NativeJS != 'undefined') {
          // eslint-disable-next-line
          NativeJS.openEssential(appName, appUrl, cookieValue, appFrom);
        } else {
          console("Native JS undefined")
        }
      }
    }
  }
};

export default essentialsModule;