import notificationsApi from 'api/notifications';
const notificationsModule = {
  state: {
    showNotificationAlert: 
    {
      status: false,
      type:'',
      text: ''
    },
    notifications: [],
    selected:[]
  },
  getters: {
    notifications: state => state.notifications,
    selected: state=> state.selected,
    notificationStatus: state => state.showNotificationAlert
  },
  mutations: {
    setMyNotifications(state, notifydata) {
      let dataToMerge = notifydata;
      if(notifydata.length > 0){
        let existing = state.notifications;
        if(existing.length > 0){
          dataToMerge = notifydata.filter(obj => {
          return  existing.filter(existingObj => existingObj.id == obj.id).length == 0 
        }); }}
      state.notifications = [...state.notifications, ...dataToMerge];
    },
    resetNotific(state) {
      state.notifications = [];
    },
    setSelected(state,selected){
      state.selected = selected;
    },
    notificationDeleted(state,value){
      state.showNotificationAlert.status = true;
      if(value == true){
        state.showNotificationAlert.text = 'Notification Deleted Successfully.';
        state.showNotificationAlert.type = 'Success';
      }else{
        state.showNotificationAlert.text = 'Error, Could Not Delete Notification.';
        state.showNotificationAlert.type = 'Error';
      }
    }
  },
  actions: {
    async fetchMyNotifications({commit,dispatch},payload) {
      const wasLoading = this.getters.isLoading;
      let tokens = this.getters.tokens;
      if(!payload){
        payload = { retry : undefined};
      }
      let errorObj = { errorStatus : false,
        errorMessage : ''
      }
      commit('setAppError',errorObj);
      if (!wasLoading) {
        commit('setIsLoading', true);
      }
      commit('resetNotific');
      await notificationsApi.fetchNotifications(tokens,true)
        .then((response) => {
          let debug = "Fetch notification:"+JSON.stringify(response);
          //eslint-disable-next-line
          NativeJS.debugLog(debug);
          let myNotifications = [];
          if(response.data && response.data.length > 0){
            myNotifications = response.data.filter(notificationObj => notificationObj.appName == "StaffPortalApp");
          }else if(response.errors){
            let status = "";
            if(response.errors && response.errors.length > 0){
              status = response.errors[0].status+":";
            } 
            let errorObj = {
              errorStatus : true,
              errorMessage : status +' Something went wrong, Please try again later.'
            }
            commit('setAppError',errorObj);
          }
          myNotifications.map(notificationObj => notificationObj.notificationType = 'outgoing')
          commit('setMyNotifications', myNotifications);
        })
        .catch((error)=>{
          let debug = "Fetch Notification:"+JSON.stringify(error);
          //eslint-disable-next-line
          NativeJS.debugLog(debug);
          let status = "";
            if(error.errors && error.errors.length > 0){
              status = error.errors[0].status+":";
            }else{
              status = error+'.';
            }
            if(status.indexOf('403') != -1 || status.indexOf('401') != -1){
              let errorStatus = status.indexOf('403') != -1 ? '403' : '401';
              //eslint-disable-next-line
              NativeJS.refreshTokens(errorStatus, '');
            }else if(status.toLowerCase().indexOf('network') != -1 && (payload.retry == undefined)){
              dispatch('replay',{reqName:'fetchMyNotifications',payload:null,retry:true})
            }else{
            let errorObj = {
              errorStatus : true,
              errorMessage : status +' Something went wrong, Please try again later.'
            }
            commit('setAppError',errorObj);
            }
          let myNotifications = [];
          commit('setMyNotifications', myNotifications);
        })
        .finally(() => {
            commit('setIsLoading', false);
        });
        await notificationsApi.fetchNotifications(tokens, false)
          .then((response) => {
            let debug = "Fetch notification:" + JSON.stringify(response);
            //eslint-disable-next-line
            NativeJS.debugLog(debug);
            let notificationInfo = [];
            if (response.data && response.data.length > 0) {
              notificationInfo = response.data.filter(notificationObj => notificationObj.appName=="StaffPortalApp");
            } else if (response.errors) {
              let status = "";
              if (response.errors && response.errors.length > 0) {
                status = response.errors[0].status + ":";
              }
              let errorObj = {
                errorStatus: true,
                errorMessage: status + ' Something went wrong, Please try again later.'
              }
              commit('setAppError', errorObj);
            }
            notificationInfo.map(notificationObj => notificationObj.notificationType = 'incoming')
            commit('setMyNotifications', notificationInfo);
          })
          .catch((error) => {
            let debug = "Fetch Notification:" + JSON.stringify(error);
            //eslint-disable-next-line
            NativeJS.debugLog(debug);
            let status = "";
            if (error.errors && error.errors.length > 0) {
              status = error.errors[0].status + ":";
            } else {
              status = error + '.';
            }
            if (status.indexOf('403') != -1 || status.indexOf('401') != -1) {
              let errorStatus = status.indexOf('403') != -1 ? '403' : '401';
              //eslint-disable-next-line
              NativeJS.refreshTokens(errorStatus, '');
            } else if (status.toLowerCase().indexOf('network') != -1 && (payload.retry == undefined)) {
              dispatch('replay', {
                reqName: 'fetchMyNotifications',
                payload: null,
                retry: true
              })
            } else {
              let errorObj = {
                errorStatus: true,
                errorMessage: status + ' Something went wrong, Please try again later.'
              }
              commit('setAppError', errorObj);
            }
            let myNotifications = [];
            commit('setMyNotifications', myNotifications);
          })
          .finally(() => {
            commit('setIsLoading', false);
          });
    },
    setSelectedValue({commit},selectedValue){
      let filterObj = {...this.state.selected,...selectedValue};
      commit('setSelected', filterObj);
    },
    async updateNotifications({commit,dispatch},updateNotification){
      this.state.notificationsModule.showNotificationAlert = {
        status: false,
        text: ''
      };
      const wasLoading = this.getters.isLoading;
      if (!wasLoading) {
        commit('setIsLoading', true);
      }
      let tokens = this.getters.tokens;
      return await notificationsApi.updateNotifications(updateNotification[0].requestId,tokens).then((response)=>{
        if(response && response.status == 200){
          dispatch('fetchMyNotifications');
          commit('notificationDeleted',true);
        }else{
          commit('notificationDeleted',false);
        }
      }).catch(()=>{
        commit('notificationDeleted',false);
      })
      .finally(() => {
          commit('setIsLoading', false);
      });
    },
    updateNotificationStatus({commit},value){
      commit('notificationDeleted',value);
    }
  }
};

export default notificationsModule;