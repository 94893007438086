import API from '../settings/constants';
import axios from "axios";
const fetchNotifications = (tokens, outgoing) => {
    let debug = 'Get Notifications Request:' + outgoing ? API.NOTIFICATIONS : API.NOTIFICATIONSINCOMING;
    // eslint-disable-next-line
    NativeJS.debugLog(debug);
    return axios(outgoing ? API.NOTIFICATIONS : API.NOTIFICATIONSINCOMING, {
    method: outgoing  ? 'POST' : 'GET',
    headers: {
       "Authorization": `Bearer ${tokens.auth_token}`,
       "id-token": tokens.id_token,
       "X-Correlation-ID":'09987-ngfd-0908-hdhd-7777-k000',
       'Content-Type': 'application/json',
       'adfs': 'true'
    },
    data: outgoing ? {
      emailAddress:tokens.user_name.indexOf('@det.nsw.edu.au') > -1 ? tokens.user_name : tokens.user_name+'@det.nsw.edu.au'
    } : null
  })
};

const updateNotifications = (request_id, tokens, outgoing) => {
  let debug = 'Update Notification Request:' + outgoing ? API.NOTIFICATIONS : API.NOTIFICATIONSINCOMING;
   // eslint-disable-next-line
   NativeJS.debugLog(debug);
  return axios(outgoing ? API.NOTIFICATIONS : API.NOTIFICATIONSINCOMING, {
    method: 'DELETE',
    headers: {
       "Authorization": `Bearer ${tokens.auth_token}`,
       "id-token": tokens.id_token,
       "X-Correlation-ID":'09987-ngfd-0908-hdhd-7777-k000',
       'Content-Type': 'application/json',
       'adfs':'true'
    },
    data: {
      requestId: request_id
    }
})
};

export default { fetchNotifications, updateNotifications }
