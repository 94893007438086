import Vue from "vue"
import Vuex from "vuex"
import schoolsModule from "./modules/schools"
import notificationsModule from "./modules/notifications";
//import newsModule from "./modules/news";
import settingModule from "./modules/settings";
import directoryModule from "./modules/directory";
import essentialsModule from "./modules/essentials"
import bookmarksModule from "./modules/bookmarks"
import staffModule from "./modules/contacts"
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex)
export default new Vuex.Store({
  modules: {
    schoolsModule,
    essentialsModule,
    staffModule,
    directoryModule,
    bookmarksModule,
    //newsModule,
    notificationsModule,
    settingModule,
  },
  plugins: [createPersistedState({
    storage:sessionStorage
  })],
  state: {
    isLoading: false,
    filters:{
      Essentials:'',
      Directory:''
    },
    appTokens:{
      id_token:'',
      auth_token:'',
      refresh_token:'',
      nativeApp:false
    },
    appLevelError:{
      errorStatus:false,
      errorMessage:""
    }
  },
  getters: {
    isLoading: state => state.isLoading,
    filters: state => state.filters,
    tokens: state => state.appTokens,
    appLevelError: state => state.appLevelError
  },
  mutations: {
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setAppError(state,errorObj){
      state.appLevelError = errorObj;
    },
    setFilters(state,filterObj){
      state.filters = filterObj;
    },
    setTokens(state,tokens){
      state.appTokens = tokens;
    }
  },
  actions: {

    setFilterValue({commit},filterValue){
      
      let filterObj = {...this.state.filters,...filterValue};
      commit('setFilters', filterObj);
    },
    async fetchToken({commit}){
      let tokenObj = {
        tokensAvaiable:false,
        id_token:'',
        auth_token:'',
        user_name:'',
        refresh_token:'',
        nativeApp: false
      };

      let availableTokens = {
        id_token:false,
        auth_token:false,
        user_name: false,
        refresh_token: false
      }
      
      if(getCookie('id_token')){
        tokenObj.id_token = getCookie('id_token');
        availableTokens.id_token = true;
      }
      
      if(getCookie('access_token')){
        tokenObj.auth_token = getCookie('access_token');
        availableTokens.auth_token = true;
      }
      if(getCookie('username')){
        tokenObj.user_name = getCookie('username');
        availableTokens.user_name = true;
      }

      if(getCookie('refresh_token')){
        tokenObj.refresh_token = getCookie('refresh_token');
        availableTokens.refresh_token = true;
      }
      
      if(availableTokens.id_token && availableTokens.auth_token){
        tokenObj.tokensAvaiable = true;
      }
      if(getCookie('deeplinktype')){
        tokenObj.deepLinkType = getCookie('deeplinktype');
      }
      if(getCookie('deeplinkurl')){
        tokenObj.deepLinkUrl = getCookie('deeplinkurl');
      }
      if(getCookie('security')){
        tokenObj.security = getCookie('security');
      }
      if(getCookie('appbuild')){
        tokenObj.appBuild = getCookie('appbuild');
      }
      if(getCookie('appversion')){
        tokenObj.appVersion = getCookie('appversion');
      }
      let debugObj = 'native-app refresh token: '+ JSON.stringify(tokenObj.refresh_token);
      //eslint-disable-next-line
      NativeJS.debugLog(debugObj);
      await commit('setTokens', tokenObj);
    },
    setAppError({commit},messageInfo){
      let errorObj = {
        errorStatus : true,
        errorMessage : messageInfo
      };
      commit("setAppError",errorObj);
    },
    replay({dispatch},{reqName,retry,payload}){
      setTimeout(function(){
        if(retry){
        dispatch(reqName,{payload:payload,retry:1});
        }},1000);
    },
    ShowLoading({commit},value){
      commit('setIsLoading',value);
    }
  }
});
const getCookie = function(cookieName){
      if(document.cookie
        .split('; ')
        .find(cookie => cookie.startsWith(cookieName))){
      return document.cookie
             .split('; ')
             .find(cookie => cookie.startsWith(cookieName))
             .split('=')[1];
  }
  return false;
}