import API from '../settings/constants';
import axios from "axios";
const changePassword = (passwordObj,tokens) => {
    let debug = 'Change password Request:'+ API.CHANGEPASSWORD;
    // eslint-disable-next-line
    NativeJS.debugLog(debug);
    return axios(API.CHANGEPASSWORD+'/'+tokens.user_name, { method: 'PUT',
    headers: {
       "Authorization": `Bearer ${tokens.auth_token}`,
       "id-token": tokens.id_token,
       "X-correlation-id":'09987-ngfd-0908-hdhd-7777-k000',
       'adfs': 'true'
    },
    data: {
      'newPassword':passwordObj.new_password,
      'oldPassword': passwordObj.current_password
    }
  })
};
export default { changePassword }