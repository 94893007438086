<template>
  <v-app 
    :class="{tabletPortrait:$vuetify.breakpoint.smOnly,tabletLandscape:$vuetify.breakpoint.mdAndUp}"
  >
    <v-main
      class="mb-5"
    >
      <v-container
        class="mb-5" 
        fluid
      >
        <v-fade-transition
          mode="out-in"
        >
          <router-view
            class="mb-5"
          />
        </v-fade-transition>      
      </v-container>

      <v-dialog
        v-model="snackbar"
        retain-focus
        max-width="290"
        aria-live="polite"
        :aria-label="errorMessage"
      >
        <v-card>
          <v-card-title
            tabindex="0"
            class="headline"
          >
            {{ this.heading ? this.heading : 'Error' }}
          </v-card-title>
          <v-card-text>
            {{ this.errorMessage }}
          </v-card-text>
          <v-card-actions class="self-align-justify">
            <v-spacer />
            <v-btn
              text
              @click="snackbar = false"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
    <BottomNav
      class="bottomNav"
      absolute
    />
    <BlockUI
      aria-live="polite"
      aria-label="Loading"
      v-if="isLoading"
    />
  </v-app>
</template>
<script>
  import BottomNav from "@/components/BottomNav";
  import utils from "./settings/utils";
  import { BlockUI } from "@nswdoe/doe-ui-core";
  import { mapGetters } from "vuex";
  export default {
    name: "App",
    components: {
      BlockUI,
      BottomNav
    },
    created(){
        this.$store.dispatch('fetchToken').then(()=>{ 
          this.$store.dispatch('fetchProfile',"LOGIN").then(()=>{
          let tokens = this.$store.state.appTokens;
          let localSettings = tokens ? utils.getLocalStorage(tokens.user_name,"settings") : false;
          let settings = localSettings ? localSettings : this.$store.state.settingModule.settings;
          if(tokens.security != null){
            settings.figerprintLogin = tokens.security == "false" || tokens.security == false ? false : true;
          }
          this.$store.dispatch("updateSettings",settings);
          let filters = { Essentials:'',
                          Directory:''
                        };
          this.$store.dispatch("setFilterValue",filters);
          this.$vuetify.theme.dark = settings.darkTheme;
          let deepLink = tokens.deepLinkUrl ? tokens.deepLinkUrl : '';
          
          if(tokens.deepLinkType && tokens.deepLinkType == "essentials" && deepLink !=''){
             this.$router.push('/essentials?deeplink='+ deepLink);
          }
          });
        });
    },
    methods:{
      async checkOnlineStatus(){
      try {
        const online = await fetch("../public/favicon.ico");
        return online.status >= 200 && online.status < 300; // either true or false
      } catch (err) {
        let message = "No internet connection detected , please try again.";
        this.$store.dispatch("setAppError",message);
        return false;
      }
    }
    },
    computed: {
      ...mapGetters({
        isLoading: 'isLoading',
        errorObj: 'appLevelError'
      }),
      path(){
        return this.$route.fullPath;
      },
      errorMessage(){
        return this.errorObj && this.errorObj.errorMessage ? this.errorObj.errorMessage : '';
      },
      heading(){
        return this.errorObj && this.errorObj.heading ? this.errorObj.heading : '';
      }
    },
    data(){
      return {
        snackbar: this.errorObj ? this.errorObj.errorStatus: false,
        checkInternet: ''
      }
    },
    watch:{
      path: function(value){
        this.checkOnlineStatus();
        //eslint-disable-next-line
        NativeJS.lasturl(value);
      },
      errorObj : function(value){
        this.snackbar = value.errorStatus;
      }
    }
  };
</script>
<style lang="scss">
@import './scss/ads.scss';
@import './scss/theme.scss';
.v-application{
  font-family: Roboto !important;
  color: #121212 !important;
}
.v-app-bar.v-app-bar--fixed{
  z-index:10;
}
:focus:not(:hover) {
  outline: #cccccc auto 1px;
}
:focus{
 outline:none;
}
.container{
  padding:16px;
}
.v-snack__content .v-btn{
  height: 24px;
}
.directory.v-dialog--active{
  margin-top:-150px;
}
.tabletPortrait,.tabletLandscape{
  .searchFilters{
    padding-left:8px !important;
    padding-right:8px !important;
  }
  .swipeLeftElement{
      margin-right:-32px !important;
  }
  .articleList{
    .swipeLeftElement{
      margin-right:0px !important;
    }
    .swipeLeftBookmark{
      margin-right:-32px !important;
  }
  .swipeAttachment{
    margin-left: -56px !important;
  }
  }
  .appBarMobile .v-btn--icon{
    padding-left:8px !important;
  }
  .v-toolbar__extension{
    padding:16px !important;
    .v-card{
      padding-left:4px;
    }
  }
  .article--terms,.archive--list{
    padding:16px 32px !important;
  }
  .v-toolbar__content,
  .myEssentials,
  .article,
  .changePassword .v-card,
  .staticPages .v-card,
  .staticPages .v-expansion-panels{
    padding-left:16px !important;
    padding-right:16px !important;
  }
  .schoolBizFilter{
    padding:0 16px;
  }
  .v-list-item{
    padding-left:32px !important;
    padding-right:32px !important;
  }
  .v-toolbar__title{
    padding-left:8px !important;
  }
  .profile .profile .v-card__text{
    padding-left:8px;
    padding-right:8px;
  }
  .profile .profile,.recentSearch{
    padding-left:16px;
    padding-right:8px;
  }
  .settingHeading,.settingItem{
    padding-left:16px;
  }
}
.essential{
  background-color: #973637;
  background: linear-gradient(#de5343, #973637);
}
</style>