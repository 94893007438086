import API from '../settings/constants';

import axios from "axios";

const fetchApps = (tokens) => {
    let debug = 'Fetching Bookmarks Request:'+ API.GETBOOKMARKS;
    // eslint-disable-next-line
    NativeJS.debugLog(debug);
    if(tokens){
    return axios(API.GETBOOKMARKS + "?user=" + tokens.user_name, {
    method: 'GET',
    headers: {
       "Authorization": `Bearer ${tokens.auth_token}`,
       "id-token": tokens.id_token,
       "X-correlation-id":'09987-ngfd-0908-hdhd-7777-k000',
       'Content-Type': 'application/json',
       'adfs': 'true'
    }
  })
  }
};
export default { fetchApps }