import contactsApi from 'api/contacts';

const contactsModule = {
  state: {
    contacts: []
  },
  getters: {
    contacts: state => state.contacts
  },
  mutations: {
    setContacts(state, contacts) {
      state.contacts = contacts;
    }
  },
  actions: {
    async fetchContacts({commit}) {
      const wasLoading = this.getters.isLoading;
      if (!wasLoading) {
        commit('setIsLoading', true);
      }

      return await contactsApi.fetchContacts()
        .then((response) => {
          commit('setContacts', response);
        })
        .finally(() => {
          if (!wasLoading) {
            commit('setIsLoading', false);
          }
        });
    },
  }
};

export default contactsModule;