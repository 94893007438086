import settingsApi from '@/api/settings';
const settingModule = {
  state: {
    settings: {
    figerprintLogin: false, schoolBiz: false,
    darkTheme: false
    }
  },
  getters: {
    settings: state => state.settings
  },
  mutations: {
    setSetings(state, settings) {
      state.settings = settings;
    }
  },
  actions: {
    async updateSettings({commit},settings) {
      commit('setSetings', settings);
    },
    async changePassword({commit},passwordObj){
      const wasLoading = this.getters.isLoading;
      let tokens = this.getters.tokens;
      if (!wasLoading) {
        commit('setIsLoading', true);
      }
      return await settingsApi.changePassword(passwordObj,tokens).then((response)=>{
        let debug = "Password:"+JSON.stringify(response);
          //eslint-disable-next-line
          NativeJS.debugLog(debug);
        if(response && response.status == 200){
          let errorObj = {
            heading:"Success",
            errorStatus : true,
            errorMessage : 'Password Change Successfully...'
          };
          commit('setAppError',errorObj);
          //eslint-disable-next-line
          NativeJS.updatePasswordSuccess();
        }else if(response && response.errors && response.errors.length > 0){

          let errorObj = {
            errorStatus : true,
            errorMessage : response.errors[0].status + ': '+response.errors[0].title
          };
          commit('setAppError',errorObj);
        }
      }).catch((error)=>{
        let debug = "Password error:"+JSON.stringify(error);
          //eslint-disable-next-line
          NativeJS.debugLog(debug);
          if(error && error.response && error.response.status >= 500 && error.response.status <= 599){
            let errorObj = {
              errorStatus : true,
              errorMessage : 'Password details are invalid or do-not meet policy standards, please try again.'
            };
            commit('setAppError',errorObj);
          }else if(error && error.response && error.response.status && ( error.response.status.indexOf('403') != -1 || error.response.status.indexOf('401') != -1)){
            let errorStatus = error.response.status.indexOf('403') != -1 ? '403' : '401';
            //eslint-disable-next-line
            NativeJS.refreshTokens(errorStatus, '');
          }else if(error && error.response && error.response.status >= 400 && error.response.status <= 499){
            let errorObj = {
              errorStatus : true,
              errorMessage : 'The new password entered cannot be one previously used.'
            };
            commit('setAppError',errorObj);
          }else{
          let errorObj = {
            errorStatus : true,
            errorMessage : error
          }
          commit('setAppError',errorObj);
          }
      })
      .finally(() => {
          commit('setIsLoading', false);
      });
    }
  }
};
export default settingModule;