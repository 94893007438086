import schoolsApi from 'api/schools';

const schoolsModule = {
  state: {
    schools: []
  },
  getters: {
    schools: state => state.schools
  },
  mutations: {
    setSchools(state, schools) {
      state.schools = schools;
    }
  },
  actions: {
    async fetchSchools({commit}) {
      const wasLoading = this.getters.isLoading;
      if (!wasLoading) {
        commit('setIsLoading', true);
      }

      return await schoolsApi.fetchSchools()
        .then((response) => {
          commit('setSchools', response);
        })
        .finally(() => {
          if (!wasLoading) {
            commit('setIsLoading', false);
          }
        });
    },
  }
};

export default schoolsModule;