<template>
  <v-bottom-navigation
    app
    class="bottomBar"
    role="navigation"
    v-model="bottomNav"
  >
    <v-btn
      active-class="activeItem"
      :class="($route.path=='/' && item.itemName == 'Notifications') ? 'active--none v-btn--active' : '' "
      v-for="(item,index) in items"
      height="100%"
      :to="item.itemRoute"
      :key="item.id"
      small
      @click="analyticsCall(index)"
      :aria-label=
                  "item.itemName + (bottomNav == index ? ' activated' : '')"
      class="bottomItem"
    >
      <span 
        aria-hidden="true"
      >
      {{ item.itemName }}
      </span>
      <v-icon
        aria-hidden="true"
        :class=
              "item.itemName == 'SchoolBiz' ? 'material-icons-outlined':''"
      >
        {{ item.itemIcon }}
      </v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>
<script>
export default {
  name: "BootomNav",
  props: {
    items: {
      type: Array,
      default: function() 
          {
          return [
          { id: "nav_1",
            itemName: "Notifications",
            itemIcon: "notifications",
            itemRoute: "/notifications"
          },
          {
            id: "nav_2",
            itemName: "Essentials",
            itemIcon: "apps",
            itemRoute: "/essentials"
          },
          {
            id: "nav_3",
            itemName: "Directory",
            itemIcon: "people",
            itemRoute: "/people"
          },
          {
            id: "nav_4",
            itemName: "Settings",
            itemIcon: "settings",
            itemRoute: "/settings"
          }
        ];
      }
    }
  },
  computed: {},
  methods: {
    analyticsCall(index){
    let menuItem = index + 1;
    //eslint-disable-next-line
    NativeJS.tabIndexTapped(menuItem);
    }
  },
  data() {
    return {
      bottomNav: "recent"
    }
  }
};
</script>
<style lang="scss" scoped>
.bottomBar {
  display: flex;
  justify-content: flex-start !important;
  align-content: flex-start;
  flex-basis: 25%;
  flex-grow: 1;
  flex-shrink: 1;
  position: fixed;
}
.bottomItem {
  width: 25% !important; 
  max-width: 25% !important;
  min-width: unset !important;
  padding: 0 auto !important;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 10px !important;
  line-height: 14px !important;
  text-align: center !important;
  color: #6d7079 !important;
}
.v-item-group.v-bottom-navigation--fixed{
  z-index:999;
}
.v-item-group.v-bottom-navigation--fixed
  > .v-btn:not(.v-btn--round).v-size--default {
  padding: 0 auto !important;
}
.tabletLandscape .v-item-group.v-bottom-navigation .v-btn{
  max-width: 270px;
}
.activeItem {
  color: #041e42 !important;
}
.active--none{
  color: #041e42 !important;
  &.v-btn:before{
    background-color:currentColor !important;
    opacity: 0.08 !important;
  }
}
</style>