import bookmarksApi from 'api/bookmarks';

const bookmarksModule = {
  state: {
    bookmarks: []
  },
  getters: {
    myBookmarks: state => state.bookmarks
  },
  mutations: {
    setMyBookmarks(state, apps) {
      state.bookmarks = apps;
    }
  },
  actions: {
    async fetchMyBookmarks({commit}) {
      let tokens = this.getters.tokens;
      if (!this.getters.isLoading) {
        commit('setIsLoading', true);
      }
      
      return await bookmarksApi.fetchApps(tokens)
        .then((response) => {
          let myBookmarks = [];
          if(response.data){
           myBookmarks = response.data;
           myBookmarks = myBookmarks.filter(bookmark => !bookmark.category || (bookmark.category && bookmark.category !='school'));
          }else if(response.errors){
            let status = "";
            
            if(response.errors && response.errors.length > 0){
              status = response.errors[0].status+":";
            } 
            let errorObj = {
              errorStatus : true,
              errorMessage : status +' Something went wrong, Please try again later.'
            }
            commit('setAppError',errorObj);
          }
          return commit('setMyBookmarks', myBookmarks);
        })
        .catch((error)=>{
          let debug = "Fetch bookmarks:"+JSON.stringify(error);
          //eslint-disable-next-line
          NativeJS.debugLog(debug);
          let status = "";
          if(error && error.errors && error.errors.length > 0){
            status = error.errors[0].status+":"; }else{ status = ''+ error; }
          
          if(status.indexOf('403') != -1 || status.indexOf('401') != -1){
            let errorStatus = status.indexOf('403') != -1 ? '403' : '401';
            //eslint-disable-next-line
            NativeJS.refreshTokens(errorStatus, '');
          }else{
          let errorObj = {
            errorStatus : true,
            errorMessage : status +' Something went wrong, Please try again later.'
          }
          commit('setAppError',errorObj);
        }
          let myBookmarks = [];
          return commit('setMyBookmarks', myBookmarks);
        })
        .finally(() => {
          commit('setIsLoading', false);
        });
    }    
  }
};

export default bookmarksModule;