let getLocalStorage = function(loginUser,item){
    let loginId = loginUser != "" ? loginUser : 'user';
     let debug = "getting local storage from :"+ loginId;
     // eslint-disable-next-line
     NativeJS.debugLog(debug);
    let items = JSON.parse(localStorage.getItem(loginId)) ? JSON.parse(localStorage.getItem(loginId)) : null;
    if(items && items[item] != null){
        return JSON.parse(items[item]);
    }
    else{
        return null;
    }
}

let setLocalStorage = function(loginUser,item,value){
    let loginId = loginUser != "" ? loginUser : 'user';
    if(localStorage && localStorage.getItem(loginId)){
        let itemStore = JSON.parse(localStorage.getItem(loginId));
        itemStore[item] = value;
        localStorage.setItem(loginId,JSON.stringify(itemStore));
    }else{
        let itemStore ={ [item]: value};
        localStorage.setItem(loginId,JSON.stringify(itemStore));
    }
}
export default { getLocalStorage, setLocalStorage};