import directoryApi from 'api/directory.js';
const directoryModule = {
  state: {
    loginProfile:{},
    directory: [],
    profile:[]
  },
  getters: {
    directory: state => state.directory,
    profile: state => state.profile,
    loginProfile: state => state.loginProfile,
  },
  mutations: {
    setDirectory(state, directory) {
      state.directory = directory;
    },
    setProfile(state, profile) {
      state.profile = profile;
      state.profile.first_name = state.profile.givenName ? state.profile.givenName : state.profile.preferredGivenName ? state.profile.preferredGivenName : '';
      state.profile.last_name = state.profile.surname ? state.profile.surname : state.profile.preferredSurname ? state.profile.preferredSurname : '';
    },
    setLoginProfile(state,profile){
      state.loginProfile = profile;
      state.loginProfile.first_name = state.loginProfile.givenName ? state.loginProfile.givenName : state.loginProfile.preferredGivenName ? state.loginProfile.preferredGivenName : '';
      state.loginProfile.last_name = state.loginProfile.surname ? state.loginProfile.surname : state.loginProfile.preferredSurname ? state.loginProfile.preferredSurname : '';
    }
  },
  actions: {
    async fetchDirectory({commit},searchString) {
      
      const wasLoading = this.getters.isLoading;
      let tokens = this.getters.tokens;
      if (!wasLoading) {
        commit('setIsLoading', true);
      }

      return await directoryApi.fetchDirectory(tokens,searchString)
        .then((response) => {
          let debug = "Fetch Directory:"+JSON.stringify(response);
          //eslint-disable-next-line
          NativeJS.debugLog(debug);
          let directory = [];
          if(response.data && response.data.staff_members && response.data.staff_members.length > 0){
            directory = response.data.staff_members;
          }else if(response.data && (response.status && response.status == 200) && !response.data.staff_members){
            directory = [];
          }else if(response.errors || response.error_description){
            let errorObj = {};
            let status = "";
            if(response.errors && response.errors.length > 0){
              status = response.errors[0].status+":";
            }
            if(response.error_description){
              errorObj = {
              errorStatus: true,
              errorMessage: response.error_description
              };
            }else{
             errorObj = {
              errorStatus : true,
              errorMessage :  status +' Something went wrong, Please try again later.'
            }
          }
          commit('setAppError',errorObj);
          }else{
            let errorObj = {
              errorStatus : true,
              errorMessage :  response +' Something went wrong, Please try again later.'
            }
            commit('setAppError',errorObj);
          }
        commit('setDirectory',directory);
        })
        .catch((error)=>{
          let debug = "Fetch Directory Error:"+JSON.stringify(error);
          //eslint-disable-next-line
          NativeJS.debugLog(debug);
          let status = "";
          if(error && error.errors && error.errors.length > 0){
            status = error.errors[0].status+":";
          }else{
            status = error;
          }
          let errorObj = {
            errorStatus : true,
            errorMessage :  status +' Something went wrong, Please try again later.'
          }
          if(status.indexOf('403') != -1 || status.indexOf('401') != -1){
            let errorStatus = status.indexOf('403') != -1 ? '403' : '401';
            //eslint-disable-next-line
            NativeJS.refreshTokens(errorStatus, '');
          }else{
            commit('setAppError',errorObj);
          }
          
          let directory = [];
          return commit('setDirectory', directory);
          
        })
        .finally(() => {
            commit('setIsLoading', false);
        });
    },
    async fetchProfile({commit},userName) {
      const wasLoading = this.getters.isLoading;
      let isLoginProfile = false;
      let tokens = this.getters.tokens;
      if (!wasLoading) {
        commit('setIsLoading', true);
      }
      if(userName == "LOGIN"){
        isLoginProfile = true;
        userName = tokens.user_name;
      }
      return await directoryApi.fetchProfile(tokens,userName)
        .then((response) => {
          let debug = 'fetch profile:' + JSON.stringify(response);
          // eslint-disable-next-line
          NativeJS.debugLog(debug);
          let profile = {};
          if(response.data && response.data.length > 0){
            profile = response.data[0] && response.data[0].person ? response.data[0].person : response.data;
            profile.position_title = response.data[0].workTitle ? response.data[0].workTitle : null;
            profile.assistants = response.data[0].assistants ? response.data[0].assistants : [];
            profile.extraFunctions = response.data[0].extraFunctions ? response.data[0].extraFunctions : [];
            if(profile.identities && profile.identities.length > 0){
              profile.identities.forEach((entity) => {
                if(entity.type && entity.type.toLowerCase() == 'detuserid'){
                  profile.det_user_id = entity.value; }
              });
            }
          }else if(response.errors || response.error_description){
            let errorObj = {};
            let status = "";
            if(response.errors && response.errors.length > 0){
              status = response.errors[0].status+":";
            }
            if(response.error_description){
              errorObj = {
              errorStatus: true,
              errorMessage:  response.error_description
              };
            }else{
             errorObj = {
              errorStatus : true,
              errorMessage :  status +' Something went wrong, Please try again later.'
            }
          }
          commit('setAppError',errorObj);
          }else{
            let errorObj = {
              errorStatus : true,
              errorMessage : ' Something went wrong, Please try again later.'
            }
            commit('setAppError',errorObj);
          }
          if(isLoginProfile){
          return commit('setLoginProfile', profile);
          }else{
            return commit('setProfile', profile);
          }
        })
        .catch((error)=>{ 
          let responseObj = JSON.stringify({
            errorStatus: true,
            errorMessage: "Fetching Profile : " + JSON.stringify(tokens)+ "---- response :" + JSON.stringify(error)
          });

          // eslint-disable-next-line
          NativeJS.debugLog(responseObj);

          let status = "";
          if(error && error.errors && error.errors.length > 0){
            status = error.errors[0].status+":";
          }else{
            status = error+'.';
          }
          if(status.indexOf('403') != -1 || status.indexOf('401') != -1){
            let errorStatus = status.indexOf('403') != -1 ? '403' : '401';
            //eslint-disable-next-line
            NativeJS.refreshTokens(errorStatus, '');
          }else{
          let errorObj = {
            errorStatus : true,
            errorMessage :  status +' Something went wrong, Please try again later.'
          }
          commit('setAppError',errorObj);
          }
          let profile = {};
          return commit('setProfile', profile);
        })
        .finally(() => {
            commit('setIsLoading', false);
        });
    }
  }
};

export default directoryModule;