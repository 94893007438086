//import qs from 'qs';
import API from '../settings/constants';
import axios from "axios";
const fetchApps = (tokens,migrated) => {
    let debug = 'Fetching Essentials Request:'+ API.STAFF_ESSENTIALS;
    // eslint-disable-next-line
    NativeJS.debugLog(debug);
    let APITOCALL = API.STAFF_ESSENTIALS;
    if (migrated == true || migrated == "true"){
      APITOCALL += "?user=" + tokens.user_name
    }else{
      APITOCALL += "/" + tokens.user_name
    }
    if(tokens){
    return axios(APITOCALL, {
    method: 'GET',
    headers: {
       "Authorization": `Bearer ${tokens.auth_token}`,
       "id-token": tokens.id_token,
       "X-correlation-id":'09987-ngfd-0908-hdhd-7777-k000',
       'Content-Type': 'application/json',
       'adfs': 'true'
    }
  })
  }
};
const essentialCookie = (tokens) => {


    let debug = 'Getting cookie Token Request:'+ API.REFRESH_TOKEN;
    // eslint-disable-next-line
    NativeJS.debugLog(debug);

    var response = {};
    response.data = {};
    response.data.id_token = tokens.id_token

    return response
    /*
    return axios(API.REFRESH_TOKEN, { 
    method: 'POST',
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Accept": "application/json"
    },
    data: qs.stringify({
      "grant_type": "refresh_token",
      "refresh_token": tokens.refresh_token,
      "client_id": API.CLIENT.SSO_ID,
      "resource":API.CLIENT.SSO_ID
    })
  }).then((response)=>{
    return response
  })
  */
};
export default { fetchApps, essentialCookie }