import API from "../settings/constants";
import axios from "axios";

const fetchDirectory = (tokens, searchString) => {
  let debug = "Fetching profile Request:" + API.FETCHPROFILE;
  // eslint-disable-next-line
  NativeJS.debugLog(debug);
  let params = {};
  if (searchString.first_name) {
    searchString.first_name = searchString.first_name + "*";
    params["first_name"] = searchString.first_name;
  }
  if (searchString.last_name) {
    searchString.last_name = searchString.last_name + "*";
    params["last_name"] = searchString.last_name;
  }
  return axios(API.STAFF_DIRECTORY, {
    method: "GET",
    headers: getApiHeaders(tokens),
    params: params
  });
};

const fetchProfile = (tokens, user_name) => {
  let debug =
    "profile Request:" +
    API.FETCHPROFILE +
    "?user_id=" +
    user_name.replace("@det.nsw.edu.au", "");
  // eslint-disable-next-line
  NativeJS.debugLog(debug);
  if (tokens) {
    user_name = user_name.replace("@det.nsw.edu.au", "");
    return axios(API.FETCHPROFILE + "?user_id=" + user_name, {
      method: "GET",
      headers: getApiHeaders(tokens)
    });
  }
};

const patchProfile = (tokens, user_name, data) => {
  let debug =
    "profile patch: " +
    API.PATCHPROFILE +
    user_name.replace("@det.nsw.edu.au", "");
  // eslint-disable-next-line
  NativeJS.debugLog(debug);

  Object.keys(data).forEach(key => {
    // remove undefined keys, or the api will complain.
    if (data[key] === undefined) {
      delete data[key];
    }
    // for some god awful reason sending "" means "delete this field". this is a work around,
    // considering sending "delete this field" twice will cause a 500.
    if (data[key] === "") {
      data[key] = " ";
    }
    if(data[key] && data[key].indexOf("**") != -1) delete data[key]; // redacted information from the get api.
    if(data[key] == "1899-12-31") delete data[key]; // redacted dob
    // note if anyone was born on 1899-12-31 we will have issues here but uh. im assuming thats not gonna happen.
  });
  
  // the space work around wont work, because it fails mobile validation. // so the best we can do is a delete
  if (data["workPhone"] === " ") data["workPhone"] = "0400000000"
  if (data["assistantPhone"] === " ") data["assistantPhone"] = "0400000000"


  if (tokens) {
    user_name = user_name.replace("@det.nsw.edu.au", "");
    return axios(API.PATCHPROFILE + user_name, {
      method: "PATCH",
      data: data,
      headers: getApiHeaders(tokens)
    });
  }
};

function getApiHeaders(tokens) {
  return {
    Authorization: `Bearer ${tokens.auth_token}`,
    "id-token": tokens.id_token,
    "X-Correlation-ID": "09987-ngfd-0908-hdhd-7777-k000",
    "Content-Type": "application/json",
    'adfs': 'true'
  };
}

export default { fetchDirectory, fetchProfile, patchProfile };
