import 'babel-polyfill';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { OAuth, IeBlocker } from '@nswdoe/doe-ui-core';
import './registerServiceWorker'

let renderComponent, renderProps;

if (IeBlocker.isIe()) {
  renderComponent = IeBlocker;
  renderProps = {
    props: {
      block: true,
      appName: "DoE UI Template"
    }
  };
} else {
  renderComponent = App
  renderProps = {};

  // initialise oauth and other app features
  Vue.config.productionTip = false;
  
  // Global event bus
  Vue.prototype.$eventHub = new Vue();

  const oAuthConfig = {
    router: router,
    authoriseUri: 'https://sso.test.det.nsw.edu.au/sso/oauth2/authorize',
    logoutUri: 'https://sso.test.det.nsw.edu.au/sso/logout?goto=https%3A%2F%2Fssoaddonsext.test.det.nsw.edu.au%2Fssoaddon%2Flogout',
  
    // authorise url params
    params: {
      client_id: 'YOUR_APP_CLIENT_ID',
      redirect_uri: 'http://localhost:8080/login/callback',
      scope: encodeURIComponent('openid your_app_scope'),
      response_type: encodeURIComponent('token id_token')
    }
  };
    
  Vue.use(OAuth, oAuthConfig);
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(renderComponent, renderProps)
}).$mount('#app');
