import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Notifications.vue')
  },
  {
    path: '/essentials',
    name: 'My Essentials',
    component: 
      () => import('../views/Essential.vue')
  },
  {
    path: '/bookmarks',
    name: 'My Bookmarks',
    component: () => import('../views/Bookmarks.vue')
  }, 
  {
    path: '/notifications',
    name: 'Notifications',
    component: () => import('../views/Notifications.vue')
  },
  {
    path: '/people',
    name: 'People',
    component: () => import('../views/Directory.vue')
  },
  {
    path: '/directory/profile/:user_id',
    component: () => import('../views/Profile.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/Settings.vue')
  },
  {
    path: '/protected',
    name: 'Protected',
    component: () => import(/* webpackChunkName: "protected" */ '../views/Protected.vue'),
    meta: {
      protected: true
    }
  },
  { 
    path: '/About',
    component: ()=> import('../views/About.vue')
  },
  {
    path: '/directory/:path',
    component: () => import('../views/Directory.vue')
  },
  { 
    path: '/Help',
    name:'Help',
    component: ()=> import('../views/Help.vue')
  },
  { 
    path: '/Feedback',
    name:'Feedback',
    component: ()=> import('../views/Feedback.vue')
  },
  { 
    path: '/Contactus',
    name:'Contact Us',
    component: ()=> import('../views/ContactUs.vue')
  },
  { 
    path: '/Terms',
    name:'Terms And Conditions',
    component: ()=> import('../views/Terms.vue')
  },
  { 
    path: '/ChangePassword',
    name:'Change Password',
    component: ()=> import('../views/ChangePassword.vue')
  },
  { 
    path: '/UpdateProfile',
    name:'Update Profile',
    component: ()=> import('../views/UpdateProfile.vue')
  },
  { 
    path: '/Privacy',
    name:'Privacy',
    component: ()=> import('../views/Privacy.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router;
