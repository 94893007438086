const getCookie = function(cookieName){
    if(document.cookie
      .split('; ')
      .find(cookie => cookie.startsWith(cookieName))){
        
    return document.cookie
           .split('; ')
           .find(cookie => cookie.startsWith(cookieName))
           .split('=')[1];
}
return false;
};
let CLIENT = { 
    ID: getCookie("Client-id-Mulesoft") ? 
      getCookie("Client-id-Mulesoft"):'601fcff389704fe6b37f44333f147a01',
    SECRET: getCookie("Client-Secret-Mulesoft") ? getCookie("Client-Secret-Mulesoft"):'B3DD6674487f40DEAa91E416BdD06d0d',
    SSO_ID: getCookie("Client-id-SSO") ? getCookie("Client-id-SSO"):'StaffPortalApp',
    SSO_SECRET: getCookie("Client-Secret-SSO") ?
                 getCookie("Client-Secret-SSO"):'FUrXC699k5JDZ94R3brgTe3n9DwQxAu9'
};
let domainSSO = getCookie("SSO-Domain") ? getCookie("SSO-Domain"): 'https://fs.det.nsw.edu.au';
let apiDomain = "";
var edPushDomain = "";

if(getCookie('environment') && getCookie('environment') == 'test'){
  edPushDomain = "https://edpush.api.test.education.nsw.gov.au"
  apiDomain = "https://api.idmstf.test.education.nsw.gov.au"
} else if (getCookie('environment') && getCookie('environment') == 'pre'){
  edPushDomain = "https://edpush.api.pre.education.nsw.gov.au"
  apiDomain = "https://api.idmstf.pre.education.nsw.gov.au"
}else {
  edPushDomain = "https://edpush.api.education.nsw.gov.au"
  apiDomain = "https://api.idmstf.education.nsw.gov.au"
}

let updateProfile = getCookie("Profile-Domain") ? getCookie("Profile-Domain"): 'https://portal.det.nsw.edu.au';
const API = { 
    STAFF_DIRECTORY: apiDomain+"/idmstf/staff-member-profiles/search",
    MIGRATED: false,
    CHANGEPASSWORD: apiDomain+"/idmpw/pwd",
    NOTIFICATIONS: edPushDomain+"/api/v1/notifications/history/outgoing/user",
    NOTIFICATIONSINCOMING: edPushDomain + "/api/v1/notifications/history/incoming/user",
    UPDATEPROFILE: updateProfile+"/group/mobile/staff-profile",
    FETCHPROFILE: apiDomain+"/idmstf/staff-member-profiles",
    PATCHPROFILE: apiDomain+"/idmstf/staff-member-profiles/staff-member-profile/",
    REFRESH_TOKEN: domainSSO+"/adfs/oauth2/token",
    IPLANETCOOKIE: domainSSO+"/sso/json/realms/root/authenticate?authIndexType=service&authIndexValue=abt",
    CLIENT:CLIENT,
    GETBOOKMARKS: getCookie('environment') && getCookie('environment') == 'test'
      ? 'https://bookmarks.api.test.education.nsw.gov.au/test/bookmarks' : getCookie('environment') && getCookie('environment') == 'pre' ? 'https://bookmarks.api.pre.education.nsw.gov.au/pre/bookmarks' : 'https://bookmarks.api.education.nsw.gov.au/prod/bookmarks'
};
const checkUserMigrated = function () {  return true; }
if(checkUserMigrated()){
API.MIGRATED = true;
if(getCookie('environment') && getCookie('environment') == 'test'){
  API.STAFF_ESSENTIALS = "https://bookmarks.api.test.education.nsw.gov.au/test/essentials"
} else if (getCookie('environment') && getCookie('environment') == 'pre'){
  API.STAFF_ESSENTIALS = "https://bookmarks.api.pre.education.nsw.gov.au/pre/essentials"
}else {
  API.STAFF_ESSENTIALS = "https://bookmarks.api.education.nsw.gov.au/prod/essentials"
}
}
export default API;